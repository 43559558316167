import $ from 'jquery'

import select2 from 'select2'
import 'select2/dist/css/select2.css'

select2(window,$);

window.addEventListener('DOMContentLoaded', () => {
  var inviteTable = $('#user-table-id').DataTable({
    searching: false,
    paging: false,
    info: false,
    order: [[4, 'desc']]
  });
  $('#people-table-id').DataTable({
    searching: false,
    paging: false,
    info: false,
    order: [[3, 'desc']]
  });
  $('#company-table-id').DataTable({
    searching: false,
    paging: false,
    info: false,
    order: [[2, 'desc']]
  });
  var agreementTable = $('#agreement-table-id').DataTable({
    searching: false,
    paging: false,
    info: false,
    order: [[3, 'desc']],
    columnDefs: [{ "visible": false, "targets": [1,3,6,10,11,12,14] }]
  });
  $('#download-table-id').DataTable({
    searching: false,
    paging: false,
    info: false,
    order: [[5, 'desc']]
  });
  $('a.toggle-vis-agreementTable').on('click', function (e) {
        e.preventDefault();

        // Get the column API object
        var column = agreementTable.column($(this).attr('data-column'));

        if(column.visible()) {
          $(this).css('background-color', 'black');
        } else {
          $(this).css('background-color', 'white');
        }

        // Toggle the visibility
        column.visible(!column.visible());
    });
  $('a.toggle-vis-inviteTable').on('click', function (e) {
        e.preventDefault();

        // Get the column API object
        var column = inviteTable.column($(this).attr('data-column'));

        if(column.visible()) {
          $(this).css('background-color', 'black');
        } else {
          $(this).css('background-color', 'white');
        }

        // Toggle the visibility
        column.visible(!column.visible());
  });
});

window.addEventListener('DOMContentLoaded', () => {
  $('.select2').select2({
    placeholder: "Select a contact",
    width: 'resolve'
  });
});
